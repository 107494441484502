import { generalMixin } from "@/utils/general-mixin";
import { archiveGenericRecordsDetail, getGenericRecordsList } from '@/api/utility'
import cloneDeep from 'lodash/cloneDeep'

/*
  This is used by (please amend accordingly)
  1. Category management
  2. Channel management

  DO NOT CHANGE ANYTHING HERE,
  you can only override anything in your own component

  Please define the following in your component data
  listQuery: cloneDeep(filterQuery), // filterQuery self-defined
  collapse: ['brand'],
*/

export const genericRecordsListMixin = {
  data() {
    return {
      isLoading: false,
      total: 0,
      // Data
      list: [],
      // selectedList: [], // across all pages
    }
  },
  mixins: [ generalMixin ],
  computed: {
    searchKeyword: {
      get() { return this.listQuery.search },
      set(value) {
        this.listQuery.search = value
      }
    }
  },
  methods: {
    // FUNCTIONS
    handleCurrentChange(val) {
      this.listQuery.page = val.page
      this.listQuery.limit = val.limit
      this.getList()
    },
    // handleSelectionChange(selected) {
    //   this.selectedList = selected
    // },
    handleRowClick(row, column) {
      if (column.property == 'action') return
      let found = this.list.find(el => el.id == row.id)
      this.$refs['listing'].toggleRowSelection(found)
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
      this.$nextTick(() => this.$refs['filter-button'].refreshActiveFilterNum())
    },
    // ARCHIVE
    handleArchive(id) {
      if (!this.isAdmin) return
      this.$confirm(this.$t("ARCHIVE_CONFIRMATION"), this.$t("ARE_YOU_SURE"), {
        confirmButtonText: this.$t("ARCHIVE"),
        cancelButtonText: this.$t("CANCEL"),
        type: 'warning'
      })
      .then(() => {
        if (!this.fullAccess) return
        this.isLoading = true
        archiveGenericRecordsDetail(id).then(res => {
          if (res.status == 'ok') {
            this.$notify({
              type: 'success',
              message: this.$t("ARCHIVED_SUCCESSFULLY"),
              duration: this.NOTIFY_DURATION,
              position: this.NOTIFY_POSITION
            })
            // this.$refs['listing'].toggleRowSelection(this.selectedList.find(el => el.id == id), false)
            // this.handleSelectionChange(this.selectedList.filter(el => el.id != id))
          }
        })
        .catch(this.handleError)
        .finally(() => {
          this.isLoading = false
          this.getList()
          if (this.handleAfterArchive) {
            this.handleAfterArchive()
          }
        })
      })
      .catch(() => {});
    },

    // GENERAL
    getList() {
      // console.log('Sending: ', this.listQuery)
      this.isLoading = true
      getGenericRecordsList(this.listQuery)
      .then(res => {
        if (res.status != 'ok') return
        console.log('Got: ', res)
        this.list = cloneDeep(res.result.list)
          .sort((a, b) => a.sequences - b.sequences)
        this.total = res.result.total

        // Back to previous page if empty data page
        const page = res.result.page
        const finalPage = res.result.finalPage
        if (finalPage < page) {
          this.listQuery.page = finalPage;
          this.getList();
        }

      })
      .catch(this.handleError)
      .finally(() => this.isLoading = false)
    },
  },
  watch: {
    // selectedList: {
    //   handler(newValue) {
    //     if (newValue.length == 0) this.$refs['listing'].clearSelection() // just to confirm
    //   }, deep: true
    // }
  },
  mounted() {
    this.getList()
  }
}