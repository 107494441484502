<template>
  <div id="channel-management">

    <table-action-row
      :filterList="listQuery"
      @onFilterChange="value => { listQuery = value; getList() }"
      :handleAction="fullAccess ? () => goTo('/bo/channel/detail') : null"
      actionButtonCode="ADD_CHANNEL"
      actionButtonIcon="icon-add"
    />

    <el-table
      v-loading="isLoading"
      class="newsily-bo-table"
      :data="list">
      <el-table-column
        prop="channel"
        :label="$t('CHANNEL_NAME')"
        width="200"
      >
        <template slot-scope="{row}">
          {{ row | translate("", "en") }}
        </template>
      </el-table-column>
      <el-table-column
        prop="code"
        :label="$t('CHANNEL_URL')"
        :width="isMobile ? 200 : null"
      />
      <el-table-column
        prop="status"
        :label="$t('STATUS')"
        width="100"
      >
        <template slot-scope="{row}">
          <div>
            <span :class="getStatusClass(row.status)">{{ $t(row.status) }}</span>
            <el-dropdown
              class="action-button"
              :hide-on-click="false"
              trigger="click"
            >
              <i class="custom-icon icon-edit mini center-in-div pointer"/>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item 
                  @click.native="goTo(`/bo/channel/detail/${row.id}`)"
                >
                  {{ fullAccess ? $t("EDIT") : $t("VIEW") }}
                </el-dropdown-item>
                <el-dropdown-item 
                  v-if="fullAccess"
                  @click.native="handleArchive(row.id)"
                >
                  {{ $t("ARCHIVE") }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-loading="isLoading"
      v-show="total > 0"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      :total="total"
      @pagination="handleCurrentChange"
    />

  </div>
</template>

<script>
import { genericRecordsListMixin } from '@/utils/_mixins/generic-records-list.js';
import TableActionRow from '@/components/table/table-action-row.vue'
import Pagination from '@/components/table/pagination.vue'
import cloneDeep from "lodash/cloneDeep"
import { PAGE_SIZES } from "@/constants";

const filterQuery = {
  page: 1,
  search: "",
  limit: PAGE_SIZES[0],
  // for API
  genericRecord: {
    type: 'channel_link'
  }
};

export default {
  name: "ChannelManagement",
  mixins: [genericRecordsListMixin],
  components: {
    Pagination, TableActionRow
  },
  data() {
    return {
      listQuery: cloneDeep(filterQuery),
      showSearch: false,
    }
  },
  methods: {
    // REDIRECTION
    goToEdit(id) {
      this.goTo(`/bo/channel/detail/${id}`)
    },
    handleAfterArchive() {
      this.$store.dispatch('app/getAllChannelLink') // to reflect the changes
    },
    handleReset() {
      this.listQuery = cloneDeep(filterQuery)
      this.handleFilter();
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch == false) {
        this.searchKeyword = ""
        this.getList()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/style/_variables.scss";
@import "@/assets/style/mixin/table.scss";
#channel-management {
  position: relative;
  @include newsily-bo-table;
  .action-button {
    float: right;
  }
}
</style>